<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="menu" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import axios from 'axios'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      menu: [],
      cek: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const nik = localStorage.getItem('nik')
      axios.get(`menu/all/horizontal/${nik}`).then(response => {
        this.menu = response.data.data
        const nama_route = this.$route.name

        this.menu.forEach(menu => {
          menu.children.forEach(children => {
            if (children.route === nama_route) {
              this.cek.push('ada')
            } else {
              this.cek.push('gakada')
            }
            if (this.cek.includes('ada') == true) {
              // console.log("boleh akses");
            } else {
              // console.log("ga boleh akses");
              // localStorage.clear();
            }
          })
        })
      }).catch(error => {}).finally(() => {})
    },
  },
  // setup() {
  //   return {
  //     navMenuItems,
  //   }
  // },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
